import { isAws } from 'src/helpers/conditions/account';
import { fetch as fetchAccount } from './account';
import { getBundles, getPlans, getSubscription } from './billing';
import { get as getCurrentUser, getGrants } from './currentUser';
import { getSubaccountSummary } from './subaccounts';

// initialize some state used for access control
export function initializeAccessControl() {
  // These will fail if the auth token has expired
  // Hides global alerts when user is logged out and redirected to /auth
  const meta = { showErrorAlert: false };

  return (dispatch, getState) =>
    Promise.all([dispatch(getCurrentUser({ meta }))]).then(([currentUser]) => {
      if (!currentUser?.access_level) {
        const subId = localStorage.getItem('currentSubId');
        const subaccount = currentUser?.subaccounts.find(
          ({ subaccount_id }) => String(subaccount_id) === String(subId)
        );

        if (!subaccount) {
          return dispatch({ type: 'REQUIRE_SUBACCOUNT' });
        } else if (subaccount.status === 'terminated') {
          localStorage.removeItem('currentSubId');
          return dispatch({ type: 'REQUIRE_SUBACCOUNT' });
        } else {
          dispatch({ type: 'SET_USER_SUBACCOUNT', payload: { subaccount } });
        }
      }

      return getAccountInfo()(dispatch, getState);
    });
}

export function setSubaccount(subaccount_id, access_level) {
  // These will fail if the auth token has expired
  // Hides global alerts when user is logged out and redirected to /auth
  return (dispatch, getState) => {
    localStorage.setItem('currentSubId', subaccount_id);
    dispatch({
      type: 'SET_USER_SUBACCOUNT',
      payload: { subaccount: { subaccount_id, access_level } }
    });
    dispatch({
      type: 'ACCESS_CONTROL_RESET'
    });
    return getAccountInfo()(dispatch, getState);
  };
}

export function getAccountInfo() {
  const meta = { showErrorAlert: false };

  return (dispatch, getState) => {
    const state = getState();
    const { currentUser } = state;
    const promises = [
      dispatch(fetchAccount({ meta })),
      dispatch(
        getGrants({
          role: currentUser?.access_level,
          policies: currentUser?.access_policies,
          meta
        })
      ),
      dispatch(getPlans({ meta })),
      dispatch(getBundles({ meta })),
      dispatch(getSubaccountSummary())
    ];

    if (!isAws(state)) {
      promises.push(dispatch(getSubscription({ meta })));
    }

    return Promise.all(promises).then(() => dispatch({ type: 'ACCESS_CONTROL_READY' }));
  };
}
