import {
  CheckboxGroupProps,
  CheckboxProps,
  Checkbox as MatchboxCheckbox
} from '@sparkpost/matchbox';
import React, { forwardRef, ForwardRefExoticComponent } from 'react';

type CheckBoxProps = ForwardRefExoticComponent<CheckboxProps> & {
  Group?: typeof Group;
};
/**
 * Matchbox Checkbox component
 * @see https://design.sparkpost.com/components/checkbox/
 */
const Checkbox: CheckBoxProps = forwardRef(
  (props, ref): JSX.Element => <MatchboxCheckbox {...props} ref={ref} />
);

const Group = (props: CheckboxGroupProps): JSX.Element => <MatchboxCheckbox.Group {...props} />;

Checkbox.displayName = 'Checkbox';
Group.displayName = 'Checkbox.Group';
Checkbox.Group = Group;

export default Checkbox as CheckBoxProps & { Group: typeof Group };
